
import sampleVideo from '../../assets/videos/sample_video.mp4'


import Logo from '../../components/base/Logo'
import { BiSearch, BiCaretDown, BiChevronDown } from 'react-icons/bi'
import SearchBox from '../../components/base/SearchBox'

import sampleThumb from '../../assets/images/thumbnails/chaeng-animated.gif'
import Footer from '../../components/base/Footer'
import FilterBox from '../../components/base/FilterBox'


import FilterTag from '../../components/base/FilterTag'
import Header from '../../components/base/Header'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FilterView from '../../components/SearchFilter/FilterView'
import { useState } from 'react'
import VideoItem from '../../components/main/VideoItem'
import KeywordItem from '../../components/main/KeywordItem'
import RegionFilterItem from '../../components/main/RegionFilterItem'
import SearchButton from '../../components/base/SearchButton'
import { FILTER_CATEGORY_MAPPING } from '../../constants/filters'
import ColorFilterTag from '../../components/base/ColorFilterTag'




const Presenter = ({filterState, setFilterState, resetFilter, keywords, setKeywords, videos, setIsQueryUpdated}) => {
    const [filterViewState, setFilterViewState] = useState(false)
    const [regionFilterText, setRegionFilterText] = useState("ALL")


    const closeFilterView = () => {
        setFilterViewState(false)
    }


    function extractFilterList(obj) {

        const cObject = JSON.parse(JSON.stringify(obj))

        console.log(JSON.stringify(cObject))

        const result = Object.keys(cObject)
            .filter(key => key !== '지역') // '지역'을 제외한 키만 필터링
            .reduce((acc, key) => acc.concat(cObject[key]), []); // 각 항목의 배열을 평탄화

        
        return result
    }
    

    // Enter 키 이벤트 처리 함수
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Enter 키가 눌렸을 때 실행할 코드
            // console.log('Enter 키가 눌렸습니다:', keywords);
            // 필요에 따라 추가 로직을 여기에 작성하세요 (예: API 호출)

            setIsQueryUpdated(true)
        }
    };


    const removeFilter = (type, item) => {
        let cObject = JSON.parse(JSON.stringify(filterState))

        let index = cObject[type].indexOf(item); // 배열에서 아이템의 인덱스를 찾음

        if (index !== -1) {
            cObject[type].splice(index, 1); // 인덱스를 기반으로 아이템 제거
        }


        setFilterState(cObject)
    }


    return (
        <div        >
            {
                filterViewState ?
                    <div
                        style={{
                            position: 'fixed',
                            left: '0',
                            top: '0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100dvh',
                            zIndex: '999',
                            background: '#FFFFFF16'
                        }}
                    >
                        {/* Filter View Wrapper */}
                        <div
                            style={{
                                background: 'black',
                                height: '70%',
                                width: '70%',
                                border: '1px solid #FFFFFF22',
                                zIndex: '99999',
                                // padding: '24px',
                                borderRadius: '12px',
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"

                                
                            }}
                        >
                            <FilterView 
                                closeHandler={closeFilterView}
                                originalFilterState={filterState}
                                setOriginalFilterState={setFilterState}
                                setIsQueryUpdated={setIsQueryUpdated}
                                
                            />

                        </div>

                    </div>
                :

                    null
            }

            <Header/>

            {/* 검색 섹션 */}
            <div
                style={{
                    background: 'black',
                    width: '100vw',
                    paddingTop: '250px',
                    paddingBottom: '200px',
                    display: 'flex',
                    justifyContent: 'center',

                }}
            >

                <div
                    style={{
                        minWidth: '1200px',
                        width: '1200px',
                        height: '368px',
                        // border: '1px solid white',
                        display: 'flex',
                        flexGrow: '0',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        padding: '0',
                    }}
                >
                    <div
                        style={{
                            // border: '1px solid yellow',
                            // width: '612px',
                            height: '253px',
                            flexGrow: '0',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: '30px',
                            padding: '0'
                        }}
                    >
                        <span
                            style={{
                                height: '78px',
                                fontFamily: "PretendardBold",
                                fontSize: '60px',
                                color: '#FFF',
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: '1.3',
                                letterSpacing: '3px',
                                textAlign: 'left',
                            }}
                        >
                            지역별 영상 소스 둘러보기
                        </span>

                        {/* <span
                            style={{
                                color: 'white'
                            }}
                        >
                            {
                                
                                JSON.stringify(filterState)
                            }
                        </span> */}
                        <div
                            style={{
                                width: '540px',
                                backgroundColor: '#1a1a1a',
                                borderRadius: '5000px',
                                // padding: '20px 30px',
                                gap: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                padding: '20px 30px'
                            }}
                        >
                            
                            <SearchButton eventHandler={()=>{setIsQueryUpdated(true)}}/>


                            <input
                                placeholder='영상 푸티지 검색'
                                value={keywords}
                                onChange={(e)=>{
                                    setKeywords(e.target.value)
                                }}
                                onKeyDown={handleKeyPress}
                                style={{
                                    width: '158px',
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent',
                                    flexGrow: '1',
                                    fontFamily: 'PretendardRegular',
                                    fontSize: '24px',
                                    fontWeight: 'normal',
                                    lineHeight: '1.3',
                                    letterSpacing: 'normarl',
                                    textAlign: 'left',
                                    color: '#EFEFEF'
                                }}
                            >
                               
                            </input>

                        </div>


                        <div
                            style={{
                                width: '380px',
                                height: '44px',
                                display: 'flex',
                                flexGrow: '0',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '5px',
                                opacity: '0.5',
                                borderRadius: '10px',
                                border: 'solid 0 #fff'
                            }}
                        
                        >
                            <span
                                style={{
                                    color: '#ffffff',
                                    fontSize: '16px',
                                    lineHeight: '1.3',
                                    flexGrow: '0.5',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    opacity: '0.5',
                                    textShadow: '0 4px 4px rgba(0,0,0,0.25)'
                                }}
                            >
                                
                                추천 검색어:
                            </span>

                            {
                                ["드론", "음식", "바다", "자연", "타임랩스", "인물", "기술", "도심"].map((item, index) => {
                                    return (
                                        <KeywordItem
                                            text={item}
                                            eventHandler={
                                                () => {
                                                    setKeywords(item)
                                                }
                                            }
                                        />
                                    )
                                })
                            }

                        </div>

                    </div>


                    {/* 검색항목 부분 */}
                    <div
                        style={{
                            // border: '1px solid white',
                            width: '197px',
                            display: 'flex',
                            flexDirection: 'column',

                        }}
                    >                                    
                        <RegionFilterItem 
                            item={"ALL"}
                            eventHandler={()=> {
                                let cObject = JSON.parse(JSON.stringify(filterState))
                                cObject["지역"] = []
                                
                                setFilterState(cObject)


                                setRegionFilterText("ALL")


                                setIsQueryUpdated(true)
                            }}
                            activateState={regionFilterText === "ALL"}
                        />
                        <RegionFilterItem 
                            item={"Seoul"}
                            eventHandler={()=> {
                                let cObject = JSON.parse(JSON.stringify(filterState))
                                cObject["지역"] = [1]
                                
                                setFilterState(cObject)
                                setRegionFilterText("Seoul")

                                setIsQueryUpdated(true)
                            }}
                            activateState={regionFilterText === "Seoul"}
                        />
                        <RegionFilterItem 
                            item={"Jeju"}
                            eventHandler={()=> {
                                let cObject = JSON.parse(JSON.stringify(filterState))
                                cObject["지역"] = [5]
                                
                                setFilterState(cObject)
                                setRegionFilterText("Jeju")

                                setIsQueryUpdated(true)
                            }}
                            activateState={regionFilterText === "Jeju"}
                        />
                        <RegionFilterItem 
                            item={"Free"}
                            eventHandler={()=> {
                                let cObject = JSON.parse(JSON.stringify(filterState))
                                cObject["지역"] = []
                                
                                setFilterState(cObject)
                                setRegionFilterText("Free")

                                setIsQueryUpdated(true)
                            }}
                            activateState={regionFilterText === "Free"}
                        />
                        <RegionFilterItem 
                            item={"University(준비중)"}
                            eventHandler={()=> {
                                // setRegionFilterText("Free")
                            }}
                            activateState={false}
                        />
                        <RegionFilterItem 
                            item={"ETC"}
                            eventHandler={()=> {
                                let cObject = JSON.parse(JSON.stringify(filterState))
                                cObject["지역"] = [22]
                                
                                setFilterState(cObject)
                                setRegionFilterText("ETC")

                                setIsQueryUpdated(true)
                            }}
                            activateState={regionFilterText === "ETC"}
                        />
                    </div>

                </div>
            </div>

            {/* Video List Section */}
            <div
                style={{
                    width: '100%',
                    paddingLeft: '50px',
                    paddingRight: '50px',
                    background: 'black',
                }}
            >
                {/* Search Filter */}
                <div
                    style={{
                        width: 'calc(100% - 100px)',
                        display: 'flex',
                        columnGap: '20px'
                    }}
                >

                    <FilterBox 
                        filterText={"최대 해상도"} 
                        selected={filterState['최대해상도'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />

                    <FilterBox 
                        filterText={"촬영 타입"} 
                        selected={filterState['촬영타입'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />
                    <FilterBox 
                        filterText={"촬영 정보"} 
                        selected={filterState['촬영정보'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />
                    <FilterBox 
                        filterText={"인물"} 
                        selected={filterState['인물'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />
                    <FilterBox 
                        filterText={"속도"} 
                        selected={filterState['속도'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />
                    <FilterBox 
                        filterText={"테마"} 
                        selected={filterState['테마'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />
                    <FilterBox 
                        filterText={"색상"} 
                        selected={filterState['색'].length > 0}
                        buttonHandler={() => {setFilterViewState(true)}}
                    />

                </div>




                {/* Option Selector */}
                <div
                    style={{
                        width: 'calc(100% - 100px)',
                        display: 'flex',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        overflow: 'hidden'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '20px',
                            flexWrap: 'wrap',
                            rowGap: '20px'
                        }}
                    >
                        {/* <span
                            style={{
                                color: 'white'
                            }}
                        >
                            {JSON.stringify(filterState)}
                        </span> */}

                        {
                            [...extractFilterList(filterState)].map((item, index) => {
                                return (
                                    FILTER_CATEGORY_MAPPING[item] === '색' ? (
                                        <ColorFilterTag
                                            key={index}
                                            item={item}
                                            deleteHandler={() => {
                                                removeFilter(FILTER_CATEGORY_MAPPING[item], item);
                                                setIsQueryUpdated(true);
                                            }}
                                        />
                                    ) : (
                                        <FilterTag
                                            key={index}
                                            item={item}
                                            deleteHandler={() => {
                                                removeFilter(FILTER_CATEGORY_MAPPING[item], item);
                                                setIsQueryUpdated(true);
                                            }}
                                        />
                                    )
                                );
                                
                            })
                        }
                    
                        
                        
                        {
                            [...extractFilterList(filterState)].length === 0 ?
                                null
                            :
                                <div
                                    onClick={()=>{
                                        resetFilter()
                                        setIsQueryUpdated(true)
                                    }}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '0px 16px'
                                    }}
                                >
                                    <span
                                        style={{
                                            color: '#adadad',
                                            fontSize: '16px',
                                            fontFamily: 'PretendardRegular',
                                            verticalAlign: 'center',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        전체 삭제
                                    </span>
                                </div>
                        }



                    </div>
                </div>


                {/* Video List Section */}
                <div
                    style={{
                        width: 'calc(100% - 100px)',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        rowGap: '20px',
                        columnGap: '20px',
                        marginTop: '24px'
                    }}
                >
                    {
                        [...videos].map((item, index) => {
                            return (

                                <VideoItem
                                    key={index}
                                    item={item}

                                />
                            )
                        })
                    }

                </div>

            </div>

            {/* Footer */}
            <Footer/>

        </div>
    )
}

export default Presenter;